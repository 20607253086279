import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../api";
import { EmailReq } from "../types";

const sendEmail = createAsyncThunk(
  "email/sendEmail",
  async (req: EmailReq, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      const accessToken = token && JSON.parse(token);
      const response = await api.post(`/api/send-mail`, req, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error: any) {
      console.log("POST_EMAIL_ERROR:=> ", error.response.data);
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export { sendEmail };
