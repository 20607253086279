import { createSlice } from "@reduxjs/toolkit";
import { RESET } from "../../utils/constants";
import { sendInvite } from "../services/rewardService";
import { RewardSlicePropType } from "../types";

const reward: RewardSlicePropType = {
  isError: false,
  message: null,
  loading: "IDLE",
};
const initialState: RewardSlicePropType = reward;

const rewardSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(RESET, () => initialState) // Reset state on RESET action

      .addCase(sendInvite.pending, (state) => {
        state.loading = "IDLE";
      })
      .addCase(sendInvite.fulfilled, (state, action) => {
        state.loading = "SUCCESS";
      })
      .addCase(sendInvite.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message =
          action?.payload?.data.message || "Sending Invite failed";
        state.loading = "IDLE";
      });
  },
});

// export const {} = rewardSlice.actions;
export default rewardSlice.reducer;
