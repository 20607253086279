import { createSlice } from "@reduxjs/toolkit";
import { RESET } from "../../utils/constants";
import { sendEmail } from "../services/emailService";

const initialEmailStatus: any = {
  loading: "IDLE",
  message: "",
  isError: false,
};

const emailSlice = createSlice({
  name: "email",
  initialState: initialEmailStatus,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(RESET, () => initialEmailStatus) // Reset state on RESET action

      //Get Wallet
      .addCase(sendEmail.pending, (state) => {
        state = "IDLE";
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.loading = "SUCCESS";
        state.message = action.payload.message;
      })
      .addCase(sendEmail.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message = action?.payload?.message || "Sending Email failed";
        state.loading = "IDLE";
      });
  },
});

// export const {  } = emailSlice.actions;
export default emailSlice.reducer;
