import React, { useEffect, useRef, useState } from "react";
import { SecuritiesType } from "../../../reduxtk/types";
import { formatToNaira } from "../../../utils/middleware";

// Define the props for the component
type NewsBannerProps = {
  data: SecuritiesType[];
  onItemClick: (item: SecuritiesType) => void;
};

// Memoize the component to avoid unnecessary rerenders when `data` doesn't change
const NewsBanner: React.FC<NewsBannerProps> = ({ data, onItemClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const tickerRef = useRef<HTMLDivElement>(null);

  // Use effect to handle hover changes and pause/resume animation
  useEffect(() => {
    const tickerElement = tickerRef.current;

    if (tickerElement) {
      // Pause animation when hovered
      if (isHovered) {
        tickerElement.style.animationPlayState = "paused";
      } else {
        tickerElement.style.animationPlayState = "running";
      }
    }
  }, [isHovered]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="relative flex-1 flex h-[20px] overflow-hidden"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={tickerRef}
        className="flex space-x-8 will-change-transform"
        style={{
          animation: "marquee 240s linear infinite",
        }}
      >
        {[...data].map((item, index) => (
          <div
            key={item.id + Math.random().toString()}
            className="flex flex-row items-center justify-center gap-2 item-preview"
            onClick={() => onItemClick(item)}
          >
            <span className="font-bold whitespace-nowrap">{item.symbol}</span>
            <span
              className={`ml-2 flex gap-2 ${
                item.change > 0
                  ? "text-green-700"
                  : item.change < 0
                  ? "text-red-700"
                  : "text-yellow-700"
              }`}
            >
              {`(${formatToNaira(item.current_price)})`}
            </span>
            <span
              className={`${
                item.change > 0
                  ? "text-green-700"
                  : item.change < 0
                  ? "text-red-700"
                  : "text-yellow-700"
              }`}
            >
              {item?.status === "Up"
                ? "▲"
                : item?.status === "Down"
                ? "▼"
                : "▲▼"}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(NewsBanner);
