import "@fortawesome/fontawesome-free/css/all.min.css";
import { memo, useEffect, useState } from "react";
import { ImMenu } from "react-icons/im";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdCloseCircleOutline,
} from "react-icons/io";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../reduxtk/hooks";
import { getSecurities } from "../../reduxtk/services/securitiesService";
import { logOut } from "../../reduxtk/slices/authSlice";
import { RootState } from "../../reduxtk/store";
import { SecuritiesType } from "../../reduxtk/types";
import { navRoutes } from "../../utils/route";
import FloatingToolTips from "./components/FloatingToolTips";
import NewsBanner from "./components/NewsBanner";

const DashboardLayout = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [activeNav, setActiveNav] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedItem, setClickedItem] = useState<SecuritiesType | null>(null); // Track the clicked news item

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { securities } = useAppSelector((state: RootState) => state.securities);

  const location = useLocation();

  useEffect(() => {
    dispatch(getSecurities());
  }, [dispatch]);
  const stringifiedUser = localStorage.getItem("user");
  const user = stringifiedUser && JSON.parse(stringifiedUser);

  const handleNavClick = (
    index: number,
    hasNestedRoutes: boolean,
    path: string
  ) => {
    if (hasNestedRoutes) {
      setActiveNav(activeNav === index ? null : index);
    } else {
      setActiveNav(null);
      window.location.pathname = path;
    }
  };

  const logOutFromMerigame = () => {
    dispatch(logOut());
    navigate("/login", { replace: true });
  };

  const isActive = (path: string) => location.pathname === path;

  const handleNewsItemClick = (item: any) => {
    setClickedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setClickedItem(null);
  };

  const sell = () => {
    setIsModalOpen(false);
    setClickedItem(null);
    navigate("orders/submit_an_order", {
      state: { security: clickedItem, operationType: "SELL" },
    });
  };

  const buy = () => {
    setIsModalOpen(false);
    setClickedItem(null);
    navigate("orders/submit_an_order", {
      state: { security: clickedItem, operationType: "BUY" },
    });
  };

  return (
    <div className="relative box-content flex max-w-[100vw] lg:h-[100vh] text-black bg-theme1-dashtext ">
      {/*Floating Modal */}
      {isModalOpen && clickedItem && (
        <FloatingToolTips
          isOpen={isModalOpen}
          clickedItem={clickedItem}
          closeModal={closeModal}
          sell={sell}
          buy={buy}
        />
      )}
      {/* <SideBar
        showMobileMenu={showMobileMenu}
        navRoutes={navRoutes}
        isActive={isActive}
        handleNavClick={handleNavClick}
        logOutFromMerigame={logOutFromMerigame}
      /> */}

      <div
        className={`${
          showMobileMenu ? "block" : "hidden"
        } fixed top-20 left-2 z-50 lg:relative lg:top-0 lg:flex lg:left-0 flex-col w-auto lg:w-[6vw] group transition-all duration-300 bg-white border-2 shadow-sm text-theme1-black border-theme1-dashtext hover:w-auto`}
      >
        <div className="hidden p-4 lg:flex">
          <img
            src={require("../../assets/images/merigame_green_logo.png")}
            height="50"
            width="100"
            alt="meristem logo"
          />
        </div>

        <nav
          className={`
            lg:flex flex-col p-2 space-y-2 `}
        >
          {navRoutes.map((route, index) => (
            <div key={route.name} className="">
              <div
                className={`flex items-center p-2 transition duration-300 rounded cursor-pointer hover:border hover:border-theme1-secondary hover:text-theme1-secondary ${
                  isActive(route.path)
                    ? "text-theme1-secondary font-bold"
                    : "text-theme1-dashtext2"
                } `}
                onClick={() =>
                  route.name.toLowerCase() === "logout"
                    ? logOutFromMerigame()
                    : handleNavClick(
                        index,
                        route.nestedRoutes.length > 0,
                        route.path
                      )
                }
              >
                <i
                  className={`mr-2  fas ${
                    route.name === "dashboard"
                      ? "fa-home"
                      : route.name === "orders"
                      ? "fa-box"
                      : route.name === "portfolio"
                      ? "fa-briefcase"
                      : route.name === "statement"
                      ? "fa-file-alt"
                      : route.name === "referrals"
                      ? "fa-user-friends"
                      : route.name === "fund"
                      ? "fa-money-bill"
                      : "fa-power-off"
                  } ${
                    isActive(route.path)
                      ? "text-theme1-secondary "
                      : "text-theme1-faintText"
                  }`}
                ></i>
                <span
                  className={`text-sm  lg:text-md font-semibold transition-opacity duration-300 lg:opacity-0 lg:group-hover:opacity-100 cursor-pointer ${
                    isActive(route.path)
                      ? "text-theme1-secondary"
                      : "text-theme1-faintText"
                  }`}
                >
                  {route.text}
                </span>
              </div>
              {activeNav === index && route.nestedRoutes.length > 0 && (
                <div className="ml-2">
                  {route.nestedRoutes.map((nestedRoute) => (
                    <Link
                      key={nestedRoute.name}
                      to={nestedRoute.path}
                      className={`flex items-center p-2 transition duration-300 rounded group ${
                        isActive(nestedRoute.path)
                          ? "text-theme1-dashboard "
                          : "text-theme1-faintText"
                      }`}
                      onClick={() => setShowMobileMenu(!showMobileMenu)}
                    >
                      <i
                        className={`mr-2  fas ${
                          nestedRoute.name === "submit_order"
                            ? "fa-paper-plane"
                            : nestedRoute.name === "view_orders"
                            ? "fa-eye"
                            : nestedRoute.name === "portfolio_overview"
                            ? "fa-chart-bar"
                            : nestedRoute.name === "asset_allocation"
                            ? "fa-pie-chart"
                            : nestedRoute.name === "account_summary"
                            ? "fa-file-invoice"
                            : nestedRoute.name === "purchase_contract"
                            ? "fa-shopping-cart"
                            : nestedRoute.name === "sell_contract"
                            ? "fa-dollar-sign"
                            : nestedRoute.name === "cash"
                            ? "fa-wallet"
                            : nestedRoute.name === "withdraw"
                            ? "fa-wallet"
                            : nestedRoute.name === "deposit"
                            ? "fa-money-bill"
                            : ""
                        } ${
                          isActive(nestedRoute.path)
                            ? "text-theme1-secondary"
                            : "text-theme1-faintText"
                        }`}
                      ></i>
                      <span
                        className={`text-sm lg:text-md font-bold lg:opacity-0 lg:group-hover:opacity-100  ${
                          isActive(nestedRoute.path)
                            ? "text-theme1-secondary"
                            : "text-theme1-faintText"
                        }`}
                      >
                        {nestedRoute.text}
                      </span>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>

      {/* Main Content */}

      <div className="flex flex-col box-content w-full lg:h-[98vh] ">
        <header
          className={`fixed top-0 left-0 right-0 flex flex-row items-center justify-between p-2 py-4 bg-white border-2 lg:z-0 z-50 `}
        >
          <section className="bg-slate-50">
            {showMobileMenu ? (
              <button onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <IoMdCloseCircleOutline
                  size={28}
                  color={"red"}
                  className="absolute z-50 left-4 top-4 lg:hidden"
                />
              </button>
            ) : (
              <button onClick={() => setShowMobileMenu(!showMobileMenu)}>
                <ImMenu
                  size={24}
                  color={"black"}
                  className="absolute z-50 left-4 top-4 lg:hidden"
                />
              </button>
            )}
          </section>
          <NewsBanner
            data={securities.secList}
            onItemClick={handleNewsItemClick}
          />

          <div className="flex flex-row gap-8">
            <div className="relative flex-row pl-8 border-l-2 border-black-50 lg:flex lg:items-center">
              <button
                className="flex flex-row items-center gap-2 text-xs font-bold text-slate-500 "
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
              >
                {`${user?.firstname}`}
                {showMenu ? (
                  <IoIosArrowUp size={18} className="flex " />
                ) : (
                  <IoIosArrowDown size={18} className="flex" />
                )}
              </button>
              {showMenu && (
                <div className="absolute right-0 mt-2 transition-all duration-1000 bg-white border rounded shadow-lg top-8">
                  <Link
                    to="orders/submit_an_order"
                    onClick={() => setShowMenu(false)}
                    className="block px-4 py-2 text-xs font-bold whitespace-nowrap hover:bg-green-900 hover:text-white text-theme1-lightText"
                  >
                    Trade Now
                  </Link>
                  <button
                    onClick={logOutFromMerigame}
                    className="block w-full px-4 py-2 text-xs font-bold text-left hover:bg-red-900 hover:text-white text-theme1-lightText"
                  >
                    Log Out
                  </button>
                </div>
              )}
            </div>
          </div>
        </header>

        <section className="flex flex-row items-center justify-between p-2 mt-16 border lg:shadow-sm border-slate-200">
          <h2 className="text-xl font-bold">Dashboard </h2>
          <section className="flex-row hidden gap-4 lg:flex">
            <Link to="orders/submit_an_order">
              <button className="px-2 py-2 font-bold text-center text-white bg-green-900 border border-white rounded-md outline w-60 text-md hover:bg-green-700 hover:text-white">
                Trade Now
              </button>
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=com.meristem.meritradeapp&hl=en">
              <button className="px-2 py-2 font-semibold text-center border border-green-900 rounded-md w-60 text-md hover:bg-slate-600 hover:text-white">
                Meritrade on Play Store
              </button>
            </Link>
            <Link to="https://apps.apple.com/ng/app/meritrade/id977562417">
              <button className="px-2 py-2 font-semibold text-center border border-green-900 rounded-md w-60 text-md hover:bg-slate-400 hover:text-white">
                MeriTrade on App Store
              </button>
            </Link>
          </section>
        </section>

        <main className="relative w-full lg:h-[98vh] overflow-y-auto ">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default memo(DashboardLayout);
