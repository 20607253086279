import { createSlice } from "@reduxjs/toolkit";
import { RESET } from "../../utils/constants";
import {
  getAllShareIndex,
  getBlogNews,
  getDashboard,
  getLeaderboard,
} from "../services/dashboardService";
import { Feed, PortfolioBoardDataType, RankingData } from "../types";

type initialDbDataWrapperType = {
  portfolioDbData: PortfolioBoardDataType;
  rankingData: RankingData;
  allShareIndex: any;
  blogNews: Feed;
  loading: "IDLE" | "PENDING" | "FAIL" | "SUCCEED";
  message: string;
  isError: boolean;
};

const initialDashboardData: initialDbDataWrapperType = {
  blogNews: {
    version: "",
    user_comment: "",
    next_url: "",
    home_page_url: "",
    feed_url: "",
    language: "",
    title: "",
    icon: "",
    items: [],
    length: 0,
  },
  portfolioDbData: {
    total_portfolio_valuation: 0,
    equity: 0,
    available_balance: 0,
    total_cash_balance: 0,
    equity_asset_value: 0,
    equity_percentage: 0,
    percentage_gainers: [],
    percentage_loosers: [],
    value_gainers: [],
    value_loosers: [],
  },
  rankingData: {
    first: {
      rank: "",
      user: {
        name: "",
        username: "",
        img: "",
      },
      status: "Up",
      portfolio_value: "",
      gainorloss: "",
      lasttradedate: "",
      lasttradetime: "",
    },
    second: {
      rank: "",
      user: {
        name: "",
        username: "",
        img: "",
      },
      status: "Up",
      portfolio_value: "",
      gainorloss: "",
      lasttradedate: "",
      lasttradetime: "",
    },
    third: {
      rank: "",
      user: {
        name: "",
        username: "",
        img: "",
      },
      status: "Up",
      portfolio_value: "",
      gainorloss: "",
      lasttradedate: "",
      lasttradetime: "",
    },
    fourth: {
      rank: "",
      user: {
        name: "",
        username: "",
        img: "",
      },
      status: "Up",
      portfolio_value: "",
      gainorloss: "",
      lasttradedate: "",
      lasttradetime: "",
    },
    list: [],
  },
  allShareIndex: [],
  loading: "IDLE",
  message: "",
  isError: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardData,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(RESET, () => initialDashboardData) // Reset state on RESET action

      //Get Dashboard
      .addCase(getDashboard.pending, (state) => {
        state.loading = "IDLE";
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.loading = "SUCCEED";
        state.portfolioDbData = action?.payload?.data;
        state.message = action.payload.message;
      })
      .addCase(getDashboard.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message =
          action?.payload?.message || "Getting Dashboard data failed";
        state.loading = "IDLE";
      })
      //Get Leaderboard data / Ranking Data
      .addCase(getLeaderboard.pending, (state) => {
        state.loading = "IDLE";
      })
      .addCase(getLeaderboard.fulfilled, (state, action) => {
        state.loading = "SUCCEED";
        state.rankingData = action?.payload?.data;
        state.message =
          action?.payload?.message || "Ranking Data is successful";
      })
      .addCase(getLeaderboard.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message =
          action?.payload?.message || "Getting Leaderboard Data failed";
        state.loading = "IDLE";
      })

      //Get All Share Index Data
      .addCase(getAllShareIndex.pending, (state) => {
        state.loading = "IDLE";
      })
      .addCase(getAllShareIndex.fulfilled, (state, action) => {
        state.loading = "SUCCEED";
        state.allShareIndex = action?.payload?.data;
        state.message = action.payload.message;
      })
      .addCase(getAllShareIndex.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message =
          action?.payload?.message || "Getting Leaderboard Data failed";
        state.loading = "IDLE";
      })

      //Get Blog News
      .addCase(getBlogNews.pending, (state) => {
        state.loading = "IDLE";
      })
      .addCase(getBlogNews.fulfilled, (state, action) => {
        state.loading = "SUCCEED";
        state.blogNews = action?.payload;
        state.message = action.payload.message;
      })
      .addCase(getBlogNews.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message =
          action?.payload?.message || "Getting Blog News Data failed";
        state.loading = "IDLE";
      });
  },
});

// export const {  } = emailSlice.actions;
export default dashboardSlice.reducer;
