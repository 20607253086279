import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import moment from "moment";
import api from "../api";

const getDashboard = createAsyncThunk(
  "dashboard/getDashboard",
  async (_, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      const accessToken = token && JSON.parse(token);
      const response = await api.get(`/api/dashboard`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error: any) {
      console.log("GET_DASHBOARD_ERROR:=> ", error.response.data);
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

const getLeaderboard = createAsyncThunk(
  "dashboard/getLeaderboard",
  async (type: "week" | "month" | "all", { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      const accessToken = token && JSON.parse(token);
      const response = await api.get(`/api/leaderboard?type=${type}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error: any) {
      console.log("GET_LEADERBOARD_ERROR:=> ", error.response.data);
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

const getAllShareIndex = createAsyncThunk(
  "dashboard/getAllShareIndex",
  async (_, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      const accessToken = token && JSON.parse(token);
      const response = await api.get(
        `/api/securities/index?start=2024-01-01&end=${moment().format(
          "YYYY-MM-DD"
        )}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      console.log("GET_ALL_SHARE_INDEX ->>>> ", response);
      return response.data;
    } catch (error: any) {
      console.log("GET_ALL_SHARE_INDEX_ERROR:=> ", error.response.data);
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

const getBlogNews = createAsyncThunk(
  "dashboard/getBlogNews",
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`https://blog.meristemng.com/feed/json`);
      return response.data;
    } catch (error: any) {
      console.log("GET_BLOG_NEWS_ERROR:=> ", error.response.data);
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export { getAllShareIndex, getBlogNews, getDashboard, getLeaderboard };
