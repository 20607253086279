import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import api from "../api";
import {
  ApiError,
  ApiResponse,
  SecHisReqType,
  SecHisResType,
  SecuritiesRequestType,
  SecuritiesTradeType,
  SecuritiesType,
} from "../types";

const getSecurities = createAsyncThunk<
  ApiResponse<SecuritiesType[]>,
  void,
  { rejectValue: ApiError }
>("securities/getSecurities", async (_, { getState, rejectWithValue }) => {
  try {
    const token = localStorage.getItem("accessToken");
    const accessToken = token && JSON.parse(token);
    const response = await api.get<ApiResponse<SecuritiesType[]>>(
      `api/securities?type=Game`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    if (response.data.isError) {
      return rejectWithValue({ message: response.data.message });
    }
    return response.data;
  } catch (error: any) {
    console.error("GET_SECURITIESES_ ERROR:=> ", error.response);
    let errorMessage = "An error occurred";
    if (axios.isAxiosError(error) && error.response) {
      errorMessage = error.response.data.message;
    }
    return rejectWithValue({ message: errorMessage });
  }
});

const getSecuritiesHistory = createAsyncThunk<
  ApiResponse<SecHisResType[]>,
  SecHisReqType,
  { rejectValue: ApiError }
>(
  "securities/getSecuritiesHistory",
  async (req, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      const accessToken = token && JSON.parse(token);
      const response = await api.get<ApiResponse<any>>(
        `api/securities/history?id=1&start=${req.start}&end=${req.end}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response.data.isError) {
        return rejectWithValue({ message: response.data.message });
      }
      return response.data;
    } catch (error: any) {
      console.log("GET_SEC_HISTORY_TXNS_ERROR:=> ", error);
      //   return rejectWithValue(error?.response?.data?.message);
      let errorMessage = "An error occurred";
      if (axios.isAxiosError(error) && error.response) {
        errorMessage = error.response.data.message;
      }
      return rejectWithValue({ message: errorMessage });
    }
  }
);

const getSecuritiesTrades = createAsyncThunk<
  ApiResponse<SecuritiesTradeType[]>,
  SecuritiesRequestType,
  { rejectValue: ApiError }
>(
  "securities/getSecuritiesTrades",
  async (req, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      const accessToken = token && JSON.parse(token);
      const response = await api.get<ApiResponse<SecuritiesTradeType[]>>(
        `/api/wallet/security-trades?wallet_id=${req.wallet_id}&security_id=${req.security_id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response.data.isError) {
        return rejectWithValue({ message: response.data.message });
      }
      return response.data;
    } catch (error: any) {
      console.log("GET_SEC_TRADES_TXNS_ERROR:=> ", error);
      //   return rejectWithValue(error?.response?.data?.message);
      let errorMessage = "An error occurred";
      if (axios.isAxiosError(error) && error.response) {
        errorMessage = error.response.data.message;
      }
      return rejectWithValue({ message: errorMessage });
    }
  }
);

export { getSecurities, getSecuritiesHistory, getSecuritiesTrades };
