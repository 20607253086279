/**
 * Formats a number or string into a Naira amount with thousands separators and two decimal places for kobo.
 * @param value - The number or string to format.
 * @returns The formatted Naira amount as a string.
 */
export const formatToNaira = (value: number | string): string => {
  // Convert the input to a number if it's a string
  let num = typeof value === "string" ? parseFloat(value) : value;

  if (isNaN(num)) {
    throw new Error("Invalid number or string input");
  }

  // Format the number with thousands separators and two decimal places
  return num.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

/**
 * Formats a number with commas as thousands separators.
 * @param value - The number to format.
 * @returns A string representation of the number with commas.
 */
export const formatNumberWithCommas = (value: number): string => {
  // Convert number to string
  const valueStr = value.toString();

  // Split the number into integer and decimal parts
  const [integerPart, decimalPart] = valueStr.split(".");

  // Use regex to insert commas
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  // Return formatted number with decimal part if it exists
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
};

/**
 * Formats a number or string with a Naira sign and appropriate suffix.
 * @param input - The number or string to format.
 * @returns A formatted string with Naira sign and suffix.
 */
export const formatNairaInThousands = (input: number | string): string => {
  // Convert input to a number if it's a string
  const num = typeof input === "string" ? parseFloat(input) : input;

  // Ensure the input is a valid number
  if (isNaN(num)) {
    throw new Error("Invalid input");
  }

  // Define thresholds and suffixes
  const thresholds = [
    { value: 1e15, suffix: "F" },
    { value: 1e12, suffix: "T" },
    { value: 1e9, suffix: "B" },
    { value: 1e6, suffix: "M" },
    { value: 1e3, suffix: "K" },
  ];

  // Find the appropriate suffix and format the number
  for (const { value, suffix } of thresholds) {
    if (num >= value) {
      // Format the number with commas and two decimal places
      const formattedNumber = (num / value).toFixed(2).replace(/\.00$/, "");
      return `₦${formattedNumber}${suffix}`;
    }
  }

  // For numbers less than 1e3, return the number in full with two decimal places
  return `₦${num.toFixed(2)}`;
};

/**
 * a function to get the relevant icon for a route name.
 * @param name - The name of the route, a string.
 * @returns icons that is attached with such route.
 */
export const getIconClass = (name: string) => {
  switch (name) {
    case "dashboard":
      return "fa-home";
    case "orders":
      return "fa-box";
    case "portfolio":
      return "fa-briefcase";
    case "statement":
      return "fa-file-alt";
    case "referrals":
      return "fa-user-friends";
    case "fund":
      return "fa-money-bill";
    case "logout":
      return "fa-power-off";
    default:
      return "";
  }
};

/**
 * a function to format date string to a more detailed readable form.
 * @param dateString - The name of the route, a string.
 * @returns - returns a more detailed and readable date-time format.
 */
export const formatDateToReadableForm = (dateString: string): string => {
  // Create a Date object from the input date string
  const date = new Date(dateString);

  // Helper function to add the ordinal suffix to the day
  const getDayWithSuffix = (day: number): string => {
    if (day > 3 && day < 21) return day + "th"; // 4th to 20th, use "th"
    switch (day % 10) {
      case 1:
        return day + "st";
      case 2:
        return day + "nd";
      case 3:
        return day + "rd";
      default:
        return day + "th";
    }
  };

  // Get the day of the month with ordinal suffix
  const dayWithSuffix = getDayWithSuffix(date.getDate());

  // Get the abbreviated month name (e.g., "Nov")
  const month = date.toLocaleString("default", { month: "short" });

  // Get the full year (e.g., "2024")
  const year = date.getFullYear();

  // Get the time formatted as "10:23AM"
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const isPM = hours >= 12;
  const formattedHours = hours % 12 || 12; // convert to 12-hour format
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes; // pad minutes with 0
  const ampm = isPM ? "PM" : "AM";

  // Combine everything into the desired format
  const formattedDate = `${date.toLocaleString("default", {
    weekday: "short",
  })} ${dayWithSuffix} of ${month}. ${year} ${formattedHours}:${formattedMinutes}${ampm}`;

  return formattedDate;
};

//Phone Regex
export const phoneRegex = /^\+([1-9]{1,4})\s?([0-9]{7,15})$/;
