import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../api";

const sendInvite = createAsyncThunk(
  "reward/sendInvite",
  async (email: string, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      const accessToken = token && JSON.parse(token);
      const response = await api.get(`/api/send-invite?email=${email}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error: any) {
      console.log("SEND_INVITE_FOR_REFERRAL_ERROR:=> ", error.response.data);
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export { sendInvite };
