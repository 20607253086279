import axios from "axios";
import { showToast } from "../utils/ToastMiddleware";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      (error.response && error.response.request.status === 401) ||
      error.response.request.status === "401" ||
      error.response.data.message === "Unauthenticated." ||
      error.response.data.message === "Unauthorized"
    ) {
      showToast("error", "Unauthorized, Kindly Login in", 2000);
      localStorage.clear();

      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default api;
