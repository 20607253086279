// import { configureStore } from "@reduxjs/toolkit";

// import authReducer from "./slices/authSlice";
// import dashboardReducer from "./slices/dashboardSlice";
// import emailReducer from "./slices/emailSlice";
// import rewardReducer from "./slices/rewardSlice";
// import securitiesSlice from "./slices/securitiesSlice";
// import walletReducer from "./slices/walletSlice";

// const reducers = {
//   auth: authReducer,
//   reward: rewardReducer,
//   wallet: walletReducer,
//   securities: securitiesSlice,
//   email: emailReducer,
//   dashboard: dashboardReducer,
// };

// const store = configureStore({
//   reducer: reducers,
//   devTools: true,
// });
// export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState>;
// export default store;

// store.ts

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { RESET } from "../utils/constants";
import authReducer from "./slices/authSlice";
import dashboardReducer from "./slices/dashboardSlice";
import emailReducer from "./slices/emailSlice";
import rewardReducer from "./slices/rewardSlice";
import securitiesReducer from "./slices/securitiesSlice";
import walletReducer from "./slices/walletSlice";

// Define the reducers
const reducers = {
  auth: authReducer,
  reward: rewardReducer,
  wallet: walletReducer,
  securities: securitiesReducer,
  email: emailReducer,
  dashboard: dashboardReducer,
};

// Create a root reducer that listens for the RESET action
const rootReducer = combineReducers(reducers);

// Create a higher order reducer that handles the reset action
const rootReducerWithReset = (state: any, action: any) => {
  if (action.type === RESET) {
    // Return the initial state when RESET is dispatched
    state = undefined; // reset entire state
  }
  return rootReducer(state, action);
};

// Configure the store
const store = configureStore({
  reducer: rootReducerWithReset,
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
