import { memo } from "react";
import { Link } from "react-router-dom";
import { OutletContextType } from "../../reduxtk/types";
import { onbRouteObj } from "./OnbNavigation";

const OnbFooter: React.FC<OutletContextType> = ({ handleNavItemClick }) => {
  return (
    <footer
      className={` flex flex-col lg:flex-row bg items-center justify-between w-full message2 px-4 py-2 border-t border-slate-300`}
    >
      <section>
        <img
          src={require("../../assets/images/merigame_green_logo.png")}
          height="35"
          width="111"
          alt="meristem logo"
        />
      </section>
      <section>
        <ul className="flex flex-row justify-between gap-2 my-4 lg:gap-8">
          <Link to="/" onClick={() => handleNavItemClick(onbRouteObj[0])}>
            <li className="cursor-pointer hover:underline hover:font-extrabold">
              Home
            </li>
          </Link>
          <Link
            to="/leaderboard"
            onClick={() => handleNavItemClick(onbRouteObj[1])}
          >
            <li className="cursor-pointer hover:underline hover:font-extrabold">
              Leaderboard
            </li>
          </Link>
          <Link
            to="/education"
            onClick={() => handleNavItemClick(onbRouteObj[2])}
          >
            <li className="cursor-pointer hover:underline hover:font-extrabold">
              Education
            </li>
          </Link>
          <Link
            to="/aboutus"
            onClick={() => handleNavItemClick(onbRouteObj[3])}
          >
            <li className="cursor-pointer hover:underline hover:font-extrabold">
              About Us
            </li>
          </Link>
        </ul>
      </section>
      <section>
        <p className="font-extrabold text-center">(c) 2024 Merigame</p>
      </section>
    </footer>
  );
};

export default memo(OnbFooter);
