import { NavRouteType } from "../reduxtk/types";

export const navRoutes: NavRouteType[] = [
  {
    name: "dashboard",
    text: "Home",
    path: "/dashboard",
    image: require("../assets/images/meristem_logo.png"),
    nestedRoutes: [],
  },
  {
    name: "orders",
    text: "Orders",
    path: "/dashboard/orders",
    image: require("../assets/images/meristem_logo.png"),
    nestedRoutes: [
      {
        name: "view_orders",
        path: "/dashboard/orders/view_orders",
        text: "View",
        image: require("../assets/images/meristem_logo.png"),
        nestedRoutes: [],
      },
      {
        name: "submit_order",
        path: "/dashboard/orders/submit_an_order",
        text: "Create",
        image: require("../assets/images/meristem_logo.png"),
        nestedRoutes: [],
      },
    ],
  },
  {
    name: "portfolio",
    path: "/dashboard/portfolio",
    text: "Portfolio",
    image: require("../assets/images/meristem_logo.png"),
    nestedRoutes: [],
  },
  // {
  //   name: "portfolio",
  //   path: "/dashboard/portfolio",
  //   text: "Portfolio",
  //   image: require("../assets/images/meristem_logo.png"),
  //   nestedRoutes: [
  //     {
  //       name: "portfolio_overview",
  //       path: "/dashboard/portfolio",
  //       text: "Overview",
  //       image: require("../assets/images/meristem_logo.png"),
  //       nestedRoutes: [],
  //     },
  //     {
  //       name: "asset_allocation",
  //       path: "/dashboard/portfolio/asset_allocation",
  //       text: "Asset",
  //       image: require("../assets/images/meristem_logo.png"),
  //       nestedRoutes: [],
  //     },
  //     {
  //       name: "account_summary",
  //       path: "/dashboard/portfolio/account_summary",
  //       text: "Summary",
  //       image: require("../assets/images/meristem_logo.png"),
  //       nestedRoutes: [],
  //     },
  //   ],
  // },
  // {
  //   name: "fund",
  //   path: "/dashboard/fund",
  //   text: "Fund Mgmt",
  //   image: require("../assets/images/meristem_logo.png"),
  //   nestedRoutes: [
  //     {
  //       name: "withdraw",
  //       path: "fund_management/withdraw",
  //       text: "Purchase",
  //       image: require("../assets/images/meristem_logo.png"),
  //       nestedRoutes: [],
  //     },
  //     {
  //       name: "deposit",
  //       path: "fund_management/withdraw",
  //       text: "Sell",
  //       image: require("../assets/images/meristem_logo.png"),
  //       nestedRoutes: [],
  //     },
  //   ],
  // },
  // {
  //   name: "statement",
  //   path: "/dashboard/statement",
  //   text: "Statement",
  //   image: require("../assets/images/meristem_logo.png"),
  //   nestedRoutes: [
  //     {
  //       name: "purchase_contract",
  //       path: "/dashboard/statement/purchase_contract",
  //       text: "Purchase",
  //       image: require("../assets/images/meristem_logo.png"),
  //       nestedRoutes: [],
  //     },
  //     {
  //       name: "sell_contract",
  //       path: "/dashboard/statement/sell_contract",
  //       text: "Sell",
  //       image: require("../assets/images/meristem_logo.png"),
  //       nestedRoutes: [],
  //     },
  //     {
  //       name: "cash",
  //       path: "/dashboard/statement/cash_contract",
  //       text: "Cash",
  //       image: require("../assets/images/meristem_logo.png"),
  //       nestedRoutes: [],
  //     },
  //   ],
  // },
  {
    name: "referrals",
    path: "/dashboard/referrals",
    text: "Referrals",
    image: require("../assets/images/meristem_logo.png"),
    nestedRoutes: [],
  },
  {
    name: "logout",
    path: "/login",
    text: "Log Out",
    image: require("../assets/images/meristem_logo.png"),
    nestedRoutes: [],
  },
];

//Unicode character for naira
export const naira = `\u20A6`;
