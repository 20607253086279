import { createSlice } from "@reduxjs/toolkit";
import { RESET } from "../../utils/constants";
import {
  checkUser,
  forgotPassword,
  logInUser,
  registerUser,
  resendOtp,
  resetPassword,
  verifyOtp,
} from "../services/authService";
import { AuthSlicePropType } from "../types";

const auth: AuthSlicePropType = {
  user: null,
  isAuthenticated: false,
  isError: false,
  message: null,
  alertInfo: null,
  accessToken: null,
  loading: "IDLE",
};
const initialState: AuthSlicePropType = auth;

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addUser: (state, action) => {
      state.user = { ...state, ...action.payload };
    },
    logOut: (state) => {
      localStorage.clear();
      state = initialState;
    },
    updateIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(RESET, () => initialState) // Reset state on RESET action

      //checkUser
      .addCase(checkUser.pending, (state) => {
        state.loading = "PENDING";
      })
      .addCase(checkUser.fulfilled, (state, action) => {
        state.loading = "SUCCESS";
      })
      .addCase(checkUser.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message = action?.payload?.message || "Checking Username failed";
        state.loading = "IDLE";
      })
      //Register User
      .addCase(registerUser.pending, (state) => {
        state.loading = "PENDING";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = "SUCCESS";
        localStorage.setItem("accessToken", action.payload.access_token);
      })
      .addCase(registerUser.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message =
          action?.payload?.data?.message || "Registering user failed";
        state.loading = "IDLE";
      })

      //Login User
      .addCase(logInUser.pending, (state) => {
        state.loading = "PENDING";
      })
      .addCase(logInUser.fulfilled, (state, action) => {
        state.loading = "SUCCESS";
        const stringifiedToken = JSON.stringify(action.payload.access_token);
        const stringifiedUser = JSON.stringify(action.payload.data);
        localStorage.setItem("user", stringifiedUser);
        localStorage.setItem("accessToken", stringifiedToken);
        state.isAuthenticated = !!action.payload.access_token;
        state.message = action.payload.message;
      })
      .addCase(logInUser.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message = action?.payload?.data?.message || "Login user failed";
        state.loading = "IDLE";
      })
      //Verify Otp
      .addCase(verifyOtp.pending, (state) => {
        state.loading = "PENDING";
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        console.log("VERIFY_OTP IN SLICE : ", action);
        state.loading = "SUCCESS";
        state.accessToken = action.payload.data.accessToken;
        state.user = action.payload.data.user;
        state.message = action.payload.data.message;
      })
      .addCase(verifyOtp.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message = action?.payload?.data?.message || "Verify Otp failed";
        state.loading = "IDLE";
      })

      //resend Otp
      .addCase(resendOtp.pending, (state) => {
        state.loading = "PENDING";
      })
      .addCase(resendOtp.fulfilled, (state, action) => {
        state.loading = "SUCCESS";
        state.accessToken = action.payload.data.accessToken;
        state.user = action.payload.data.user;
        state.message = action.payload.message;
      })
      .addCase(resendOtp.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message = action?.payload?.message || "Resend Otp failed";
        state.loading = "IDLE";
      })

      //forgot_Password
      .addCase(forgotPassword.pending, (state) => {
        state.loading = "PENDING";
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        // console.log("FORGOT_PASSWORD IN SLICE : ", action);
        state.loading = "SUCCESS";
        localStorage.setItem("accessToken", action.payload.access_token);
      })
      .addCase(forgotPassword.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message = action?.payload?.message || "Forgot Password failed";
        state.loading = "IDLE";
      })
      //reset_Password
      .addCase(resetPassword.pending, (state) => {
        state.loading = "PENDING";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        // console.log("RESET_PASSWORD FULFILLED SLICE >>> ", action.payload);
        state.loading = "SUCCESS";
      })
      .addCase(resetPassword.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message =
          action?.payload?.data?.message || "Reset Password failed";
        state.loading = "IDLE";
      });
  },
});

export const { logOut, addUser, updateIsAuthenticated } = authSlice.actions;
export default authSlice.reducer;
