import CryptoJS from "crypto-js";

/**
 * Formats a number or string with a Naira sign and appropriate suffix.
 * @param text - The string to encrypt.
 * @returns encrypted string using crypto with aes-256-cbc algorithm.
 */
export const encryptData = (text: string): string => {
  return CryptoJS.AES.encrypt("message", "secret key 123").toString();
};

/**
 *Encrypting value securely.
 * @param text - string to decrypt.
 * @returns decrypted string using crypto with aes-256-cbc algorithm.
 */
export const decryptData = (encryptedText: string): string => {
  const bytes = CryptoJS.AES.decrypt(encryptedText, "secret key 123");
  const originalMessage = bytes.toString(CryptoJS.enc.Utf8);
  return originalMessage;
};

/**
 * Check if a user session is logged. Return true if it is logged in or false if not
 * @returns Return true if it is logged in or false if not.
 */
export const isUserLoggedIn = () => {
  const token = localStorage.getItem("accessToken");
  return !!token;
};

/**
 *Encrypting value securely.
 * @param key - string to store.
 * @returns returns a string or throw an error if null.
 */
export const getData = (key: string) => {
  const encryptedValue = localStorage.getItem(key);
  if (!encryptedValue) {
    throw new Error("value is null");
  }
  const encryptedData = decryptData(encryptedValue);
  return encryptedData;
};

/**
 *Encrypting value securely.
 * @param key - key string identifier to store a value.
 * @param value - value string to store using the key as an identifier to retrieve.
 */
export const storeData = (key: string, value: string) => {
  let encryptedData;
  if (typeof value === "object") {
    const stringValue = JSON.stringify(value);
    encryptedData = encryptData(stringValue);
  } else {
    encryptedData = encryptData(value);
  }

  localStorage.setItem(key, encryptedData);
};
