import { createSlice } from "@reduxjs/toolkit";
import { RESET } from "../../utils/constants";
import {
  buyOrder,
  getOrder,
  getPortfolioHoldings,
  getWallet,
  getWalletTxns,
  sellOrder,
} from "../services/walletService";
import {
  OrderSlicePropType,
  PortfolioHoldingSlicePropType,
  TransactionPropType,
  WalletData,
  WalletSlicePropType,
} from "../types";

const initialTransaction: TransactionPropType[] = [
  {
    id: 0,
    wallet_id: 0,
    transaction_type: "credit",
    gross_amount: 0,
    net_amount: 0,
    ref: "",
    balance_before: 0,
    balance_after: 0,
    transaction_purpose: "",
    metadata: null,
    created_at: "",
    updated_at: "",
  },
];

const walletData = {
  id: 0,
  user_id: 0,
  wallet_type: "",
  portfolio: "",
  balance: 0,
};

const initialOrder: OrderSlicePropType = {
  orders: [],
  loading: "IDLE",
  message: "",
  isError: false,
};

const initialPortfolioHoldings: PortfolioHoldingSlicePropType = {
  portfolioHoldings: [],
  loading: "IDLE",
  message: "",
  isError: false,
};

const wallet: WalletSlicePropType = {
  portfolioHoldings: initialPortfolioHoldings,
  walletData: walletData,
  transactions: initialTransaction,
  order: initialOrder,
  isError: false,
  message: null,
  loading: "IDLE",
};

const initialState: WalletSlicePropType = wallet;

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    addOrders: (state, action) => {
      state.order.orders = action.payload;
    },
    AddMoreOrders: (state, action) => {
      state.order.orders = [...action.payload, state.order.orders];
    },
    clearOrders: (state, action) => {
      state.order.orders = [];
    },
    addPortfolioHolding: (state, action) => {
      state.portfolioHoldings.portfolioHoldings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(RESET, () => initialState) // Reset state on RESET action

      //Get Wallet
      .addCase(getWallet.pending, (state) => {
        state.loading = "IDLE";
      })
      .addCase(getWallet.fulfilled, (state, action) => {
        state.loading = "SUCCESS";
        state.walletData = action.payload.data as WalletData;
        state.message = action.payload.message;
      })
      .addCase(getWallet.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message =
          action?.payload?.message || "Getting Wallet Details failed";
        state.loading = "IDLE";
      })
      //Get Wallet Transactions
      .addCase(getWalletTxns.pending, (state) => {
        state.loading = "IDLE";
      })
      .addCase(getWalletTxns.fulfilled, (state, action) => {
        state.loading = "SUCCESS";
        state.transactions = action.payload.data as TransactionPropType[];
      })
      .addCase(getWalletTxns.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message =
          action?.payload?.message || "Getting Wallet Details failed";
        state.loading = "IDLE";
      })
      //Get Buy Order
      .addCase(buyOrder.pending, (state) => {
        state.loading = "IDLE";
      })
      .addCase(buyOrder.fulfilled, (state, action) => {
        state.loading = "SUCCESS";
      })
      .addCase(buyOrder.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message = action?.payload?.message || "Buying Order failed";
        state.loading = "IDLE";
      })

      //Get Sell Order
      .addCase(sellOrder.pending, (state) => {
        state.loading = "IDLE";
      })
      .addCase(sellOrder.fulfilled, (state, action) => {
        state.loading = "SUCCESS";
      })
      .addCase(sellOrder.rejected, (state, action: any) => {
        state.loading = "FAIL";
        state.isError = true;
        state.message = action?.payload?.message || "Selling Order failed";
        state.loading = "IDLE";
      })

      //Get Sell Order
      .addCase(getOrder.pending, (state) => {
        state.order.loading = "IDLE";
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.order.loading = "SUCCESS";
        state.order.orders = action.payload.data;
        state.order.message = action.payload.message;
        state.order.isError = action.payload.isError;
      })
      .addCase(getOrder.rejected, (state, action: any) => {
        state.order.loading = "FAIL";
        state.order.isError = true;
        state.order.message = action.payload.message || "Get Order failed";
        state.loading = "IDLE";
      })
      //Get Portfolio Holdinds
      .addCase(getPortfolioHoldings.pending, (state) => {
        state.portfolioHoldings.loading = "IDLE";
      })
      .addCase(getPortfolioHoldings.fulfilled, (state, action) => {
        state.portfolioHoldings.loading = "SUCCESS";
        state.portfolioHoldings.portfolioHoldings = action.payload.data;
        state.portfolioHoldings.message = action.payload.message;
        state.portfolioHoldings.isError = action.payload.isError;
      })
      .addCase(getPortfolioHoldings.rejected, (state, action: any) => {
        state.portfolioHoldings.loading = "FAIL";
        state.portfolioHoldings.isError = true;
        state.portfolioHoldings.message =
          action.payload.message || "Get Portfolio Holding failed";
        state.portfolioHoldings.loading = "IDLE";
      });
  },
});

export const { addOrders, AddMoreOrders, clearOrders, addPortfolioHolding } =
  walletSlice.actions;
export default walletSlice.reducer;
