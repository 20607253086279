import React, { memo } from "react";
import { SecuritiesType } from "../../../reduxtk/types";
import { formatNairaInThousands } from "../../../utils/middleware";

type FloatingTooltipProp = {
  isOpen: boolean;
  clickedItem: SecuritiesType;
  closeModal: () => void;
  sell: () => void;
  buy: () => void;
};

const FloatingTooltip: React.FC<FloatingTooltipProp> = ({
  isOpen,
  clickedItem,
  closeModal,
  buy,
  sell,
}) => {
  if (!isOpen || !clickedItem) return null;

  return (
    <div
      onClick={closeModal}
      className="fixed inset-0 z-50 flex items-center justify-center duration-700 bg-gray-900 bg-opacity-50 transition-bg-opacity"
    >
      <div className="absolute z-50 p-4 text-xs bg-white border rounded-md shadow-md text-slate-500 top-50 left-50">
        <h3 className="mb-2 text-lg font-bold text-center text-black">
          {clickedItem?.symbol.toUpperCase()}
        </h3>
        <div className="flex flex-row justify-between gap-12 mb-1">
          <p>CURRENT PRICE</p>
          <p>
            {formatNairaInThousands(clickedItem?.current_price?.toFixed(2))}
          </p>
        </div>
        <div className="flex flex-row justify-between gap-12 mb-1">
          <p>CHANGE (%)</p>
          <p>{clickedItem?.percentage_change}%</p>
        </div>
        <div className="flex flex-row justify-between gap-12 mb-1">
          <p>STATUS</p>
          <p>{clickedItem?.status}</p>
        </div>

        <div className="flex flex-row justify-between gap-2 my-4">
          <button
            onClick={buy}
            className="flex-1 px-4 py-1 text-green-700 border border-green-700 rounded-md hover:bg-green-700 hover:text-slate-50"
          >
            Buy
          </button>
          <button
            onClick={sell}
            className="flex-1 px-4 py-1 text-red-700 border border-red-700 rounded-md hover:bg-red-700 hover:text-slate-50"
          >
            Sell
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(FloatingTooltip);
