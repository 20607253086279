import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../api";
import {
  LoginPropType,
  UserData,
  UsernamePhoneEmailCheckReqType,
  VerifyOtpPropType,
} from "../types";

const checkUser = createAsyncThunk(
  "auth/checkUser",
  async (
    req: UsernamePhoneEmailCheckReqType,
    { getState, rejectWithValue }
  ) => {
    try {
      const response = await api.get(
        `/api/user/check-user-data?type=${req.type}&value=${req.value}`
      );
      // console.log("USERNAME AVAILABLE:=> ", response);
      return response.data;
    } catch (error: any) {
      console.log("USERNAME AVAILABILITY ERROR:=> ", error);
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (req: UserData, { getState, rejectWithValue }) => {
    try {
      const response = await api.post(`/api/user/register`, req);
      return response.data;
    } catch (error: any) {
      console.log("REGISTER_USER_API_ERROR >>>>>>>> ", error);
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

const logInUser = createAsyncThunk(
  "auth/logInUser",
  async (req: LoginPropType, { getState, rejectWithValue }) => {
    try {
      const response = await api.post(`/api/user/login`, req);
      return response.data;
    } catch (error: any) {
      console.log("LOGIN_USER_API_ERROR >>>>>>>> ", error.response.data);
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (req: VerifyOtpPropType, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      // const accessToken = token && JSON.parse(token);
      const response = await api.post(`/api/user/verify-otp`, req, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error: any) {
      console.log("VERIFY_OTP_API_ERROR >>>>>>>> ", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

const resendOtp = createAsyncThunk(
  "auth/resendOtp",
  async (req: VerifyOtpPropType, { getState, rejectWithValue }) => {
    try {
      const response = await api.post(`/api/user/resend-otp`, req);
      return response.data;
    } catch (error: any) {
      console.log("RESENT_OTP_API_ERROR >>>>>>>> ", error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (req: VerifyOtpPropType, { getState, rejectWithValue }) => {
    try {
      const response = await api.post(`/api/user/forgot-password`, req);
      return response.data;
    } catch (error: any) {
      console.log("FORGOT_PASSWORD_API_ERROR >>>>>>>> ", error);
      return rejectWithValue({ message: error?.response?.data.message });
    }
  }
);

const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (req: LoginPropType, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      // const accessToken = token && JSON.parse(token);
      const response = await api.post(`/api/user/reset-password`, req, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("RESET_PASSWORD API : ", response);
      return response.data;
    } catch (error: any) {
      console.log("RESENT_OTP_API_ERROR >>>>>>>> ", error);
      return rejectWithValue({
        message: error?.response?.data?.message || "Invalid Token ...",
      });
    }
  }
);

export {
  checkUser,
  forgotPassword,
  logInUser,
  registerUser,
  resendOtp,
  resetPassword,
  verifyOtp,
};
