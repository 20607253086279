import { createSlice } from "@reduxjs/toolkit";
import { RESET } from "../../utils/constants";
import {
  getSecurities,
  getSecuritiesHistory,
  getSecuritiesTrades,
} from "../services/securitiesService";
import {
  IniSecHisStateType,
  IniSecStateType,
  IniSecTradeListStateType,
} from "../types";

type InitialSecStateType = {
  securities: IniSecStateType;
  securitiesHistory: IniSecHisStateType;
  securitiesTradeList: IniSecTradeListStateType;
};

const initialState: InitialSecStateType = {
  securities: { secList: [], isError: false, message: null, loading: "IDLE" },
  securitiesHistory: {
    secHisList: [],
    isError: false,
    message: null,
    loading: "IDLE",
  },
  securitiesTradeList: {
    secTradeList: [],
    isError: false,
    message: null,
    loading: "IDLE",
  },
};

const securitiesSlice = createSlice({
  name: "securities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(RESET, () => initialState) // Reset state on RESET action

      //Get Securities
      .addCase(getSecurities.pending, (state) => {
        state.securities.loading = "IDLE";
      })
      .addCase(getSecurities.fulfilled, (state, action) => {
        state.securities.loading = "SUCCESS";
        state.securities.secList = action.payload.data;
        state.securities.message = action.payload.message;
      })
      .addCase(getSecurities.rejected, (state, action: any) => {
        state.securities.loading = "FAIL";
        state.securities.isError = true;
        state.securities.message =
          action.payload.message || "Getting Securities Details failed";
        state.securities.loading = "IDLE";
      })

      //Get Securities History
      .addCase(getSecuritiesHistory.pending, (state) => {
        state.securitiesHistory.loading = "IDLE";
      })
      .addCase(getSecuritiesHistory.fulfilled, (state, action) => {
        state.securitiesHistory.loading = "SUCCESS";
        state.securitiesHistory.secHisList = action.payload.data;
      })
      .addCase(getSecuritiesHistory.rejected, (state, action: any) => {
        state.securitiesHistory.loading = "FAIL";
        state.securitiesHistory.isError = true;
        state.securitiesHistory.message =
          action.payload.message || "Getting Securities History failed";
        state.securitiesHistory.loading = "IDLE";
      })

      //Get Securities Trades
      .addCase(getSecuritiesTrades.pending, (state, action) => {
        state.securitiesTradeList.loading = "IDLE";
      })
      .addCase(getSecuritiesTrades.fulfilled, (state, action) => {
        state.securitiesTradeList.loading = "SUCCESS";
        state.securitiesTradeList.secTradeList = action.payload.data;
        state.securitiesTradeList.message = action.payload.message;
      })
      .addCase(getSecuritiesTrades.rejected, (state, action: any) => {
        state.securitiesTradeList.loading = "FAIL";
        state.securitiesTradeList.isError = true;
        state.securitiesTradeList.message =
          action.payload.message || "Getting Securities Trades failed";
        state.securitiesTradeList.loading = "IDLE";
      });
  },
});

// export const {} = securitiesSlice.actions;
export default securitiesSlice.reducer;
