import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import api from "../api";
import {
  ApiError,
  ApiResponse,
  GetOrderRequestType,
  GetPortHoldingsReq,
  Holding,
  OrderReqTYpe,
  OrderResponseType,
} from "../types";

const getWallet = createAsyncThunk(
  "wallet/getWallet",
  async (_, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      const accessToken = token && JSON.parse(token);
      const response = await api.get(`/api/wallet?type=Game`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error: any) {
      console.log("GET_WALLET_ ERROR:=> ", error.response.data);
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

const getWalletTxns = createAsyncThunk(
  "wallet/getWalletTxns",
  async (id: number, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("accessToken");
      const accessToken = token && JSON.parse(token);
      const response = await api.get(`/api/wallet/transactions?id=${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data;
    } catch (error: any) {
      console.log("GET_WALLET_TXNS_ERROR:=> ", error.response.data);
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

const buyOrder = createAsyncThunk<
  ApiResponse<boolean>,
  OrderReqTYpe,
  { rejectValue: ApiError }
>("wallet/buyOrder", async (req, { getState, rejectWithValue }) => {
  try {
    const token = localStorage.getItem("accessToken");
    const accessToken = token && JSON.parse(token);
    const response = await api.post<ApiResponse<boolean>>(
      `/api/wallet/buy-order`,
      req,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    if (response.data.isError) {
      return rejectWithValue({ message: response.data.message });
    }
    return response.data;
  } catch (error: any) {
    console.log("BUY_ORDER_ERROR:=> ", error);
    let errorMessage = "An error occurred";
    if (axios.isAxiosError(error) && error.response) {
      errorMessage = error.response.data.message;
    }
    return rejectWithValue({ message: errorMessage });
  }
});

const sellOrder = createAsyncThunk<
  ApiResponse<boolean>,
  OrderReqTYpe,
  { rejectValue: ApiError }
>("wallet/sellOrder", async (req, { getState, rejectWithValue }) => {
  try {
    const token = localStorage.getItem("accessToken");
    const accessToken = token && JSON.parse(token);
    const response = await api.post<ApiResponse<boolean>>(
      `/api/wallet/sell-order`,
      req,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    if (response.data.isError) {
      return rejectWithValue({ message: response.data.message });
    }
    return response.data;
  } catch (error: any) {
    console.log("SELL_ORDER_ERROR:=> ", error);
    let errorMessage = "An error occurred";
    if (axios.isAxiosError(error) && error.response) {
      errorMessage = error.response.data.message;
    }
    return rejectWithValue({ message: errorMessage });
  }
});

const getOrder = createAsyncThunk<
  ApiResponse<OrderResponseType[]>,
  GetOrderRequestType,
  { rejectValue: ApiError }
>("wallet/getOrder", async (req, { getState, rejectWithValue }) => {
  try {
    const token = localStorage.getItem("accessToken");
    const accessToken = token && JSON.parse(token);
    const response = await api.get<ApiResponse<any>>(
      `/api/wallet/trades?wallet_id=${req.walletId}&start=${req.start}&end=${req.end}&sort=desc`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    if (response.data.isError) {
      return rejectWithValue({ message: response.data.message });
    }
    return response.data;
  } catch (error: any) {
    console.log("GET_ORDER_ERROR:=> ", error);
    let errorMessage = "An error occurred";
    if (axios.isAxiosError(error) && error.response) {
      errorMessage = error.response.data.message;
    }
    return rejectWithValue({ message: errorMessage });
  }
});

const getPortfolioHoldings = createAsyncThunk<
  ApiResponse<Holding[]>,
  GetPortHoldingsReq,
  { rejectValue: ApiError }
>("wallet/getPortfolioHoldings", async (req, { getState, rejectWithValue }) => {
  try {
    const token = localStorage.getItem("accessToken");
    const accessToken = token && JSON.parse(token);
    const response = await api.get<ApiResponse<Holding[]>>(
      `/api/wallet/portfolio?wallet_id=${req.walletId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    if (response.data.isError) {
      return rejectWithValue({ message: response.data.message });
    }
    // console.log("GET_PORTFOLIO_HOLDINGS_API >>>> ", response);
    return response.data;
  } catch (error: any) {
    console.log("GET_PORTFOLIO_HOLDINGS_API_ERROR:=> ", error);
    let errorMessage = "An error occurred";
    if (axios.isAxiosError(error) && error.response) {
      errorMessage = error.response.data.message;
    }
    return rejectWithValue({ message: errorMessage });
  }
});

export {
  buyOrder,
  getOrder,
  getPortfolioHoldings,
  getWallet,
  getWalletTxns,
  sellOrder,
};
